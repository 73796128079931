import React from 'react';
import {Choice } from 'components';
import styled, { css } from 'react-emotion';
import Helmet from 'react-helmet';
import config from '../../config/website';

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
    h3{
      margin-bottom: 0.5em;
      font-size: 3em;
      text-transform: uppercase;
    }
`;

const PrintOrSculpture = () => (
  <Choice
    back
    first="yes"
    firstTo="/print"
    second="no"
    secondTo="/sculpture"
  >
    <Helmet title={`Print? | ${config.siteTitle}`} />
    <Title>
      <h3>Print?</h3>
    </Title>
  </Choice>
);

export default PrintOrSculpture;